import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The car is the last digital frontier to be conquered, and Product Manager spends his days trying to develop the best experience possible.`}</p>
    <h3>{`Tell me a little bit about yourself.`}</h3>
    <p>{`I’m the Product Manager for the #HeyMercedes Voice Assistant `}<a parentName="p" {...{
        "href": "https://mbrdna.com/"
      }}>{`in the North American market`}</a>{` and I’ve been working in the connected car field since 2016. I want to create a future where we experience a seamless interaction between devices, AI and the environment that improves and enhances our everyday lives.`}</p>
    <h3><strong parentName="h3">{`Why did Mercedes-Benz decide to leverage a voice assistant in the car?`}</strong></h3>
    <p>{`Voice in the car has been a clear strategy for us for a long time since it makes driving so much safer. Mercedes-Benz has been in the voice industry since 1996 when we first introduced a Speech Dialog System for making phone calls in the S-Class at the time. Over the years we progressed and added more features such as navigation but the biggest leap that we took was in 2018 when we introduced HeyMercedes voice assistant in the MBUX (Mercedes-Benz User Experience) infotainment system. You can simply invoke it using the “Hey Mercedes” wake up phrase and address it naturally. It can handle a wide variety of queries due to the Natural Language Understanding module that we have integrated from our partners, both embedded and in the cloud.`}</p>
    <h3><strong parentName="h3">{`What’s been the most interesting thing you learned from working on MBUX?`}</strong></h3>
    <p>{`People love to chat to our HeyMercedes Voice Assistant in MBUX! We see a large portion of interaction where our customer have casual small talk with the assistant. I think they are curious about the assistant’s personality.`}</p>
    <h3><strong parentName="h3">{`What’s a top tip you have for someone interested in developing a voice assistant?`}</strong></h3>
    <p>{`Test your voice interaction with customer as early as possible and don’t be scared of sharing your idea with other VUI designers. You will learn so many things that will save you lots of time in the long run.`}</p>
    <h3><strong parentName="h3">{`What are you looking forward to at SuperBot?`}</strong></h3>
    <p>{`I’m really excited about the presentation from `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/2020/02/11/will-hall/"
      }}>{`Will Hall`}</a>{` from RAIN Agency. I still remember the impact his presentation from SuperBot 2019 had on me so I’m looking forward to see what he has prepared for us this year.`}</p>
    <hr></hr>
    <h3>{`About Mercedes-Benz Research and Development, North America`}</h3>
    <p><em parentName="p">{`MBRDNA continuously strives to remain at the forefront of successful automotive research and development in North America. Key areas of focus include creating a digital design language for Mercedes-Benz vehicles, designing in-car instruments, hardware/software interfaces for the truly digital car, and connecting cars to the cloud and mobile devices. To learn more about this and from Mihai, register for SuperBot today.`}</em></p>
    <div className="wp-block-button aligncenter is-style-squared">
  [Register for SuperBot](https://www.dashbot.io/superbot/sb2020)
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      